import gsap from 'gsap';

export default {
    methods: {
        getEstimatedCompletionTime(words) {
            const totalWordCount = words.trim().split(/\s+/g).length;

            const wordsPerMinute = 250;
            // Accounting for writing code
            const codeContentAdjuster = 125;

            const wordsPerSecond = ((wordsPerMinute - codeContentAdjuster) / 60);

            const totalReadingTimeSeconds = totalWordCount / wordsPerSecond;
            let readingTimeMinutes = Math.floor(totalReadingTimeSeconds / 60);
            // eslint-disable-next-line
            const readingTimeSeconds = Math.round(totalReadingTimeSeconds - (readingTimeMinutes * 60));

            if (readingTimeSeconds > 30) readingTimeMinutes += 1;
            return `${readingTimeMinutes}m`;
        },

        playTutorialBanner() {
            const elemClass = '.tutorial-banner';
            const elem = document.querySelector(elemClass);
            if (!elem) return;
            gsap.to(elem, {
                duration: 0.3,
                opacity: 1,
            });
        },

        playStaggerLessonItems() {
            if (this.lessonItemsStaggered) return;

            const elemClass = '.stagger-lesson-item';
            const elems = document.querySelectorAll(elemClass);

            if (Array.from(elems).length === 0) return;

            this.lessonItemsStaggered = true;
            gsap.from(elemClass, {
                duration: 0.4,
                translateY: '-0.2rem',
                opacity: 0,
                stagger: 0.1,
            });
        },

        playTutorialCompletedAnimations() {
            const masterTimeline = gsap.timeline();
            masterTimeline
                .add(this.tutorialCompletedModalTl())
                .add(this.tutorialCompletedContentTl())
                .play();
        },

        tutorialCompletedModalTl() {
            const timeline = gsap.timeline();
            timeline
                .to(document.querySelector('.completed-modal'), {
                    duration: 0.2,
                    opacity: 0.5,
                    ease: 'power4.out',
                });
            return timeline;
        },

        tutorialCompletedContentTl() {
            const timeline = gsap.timeline();
            timeline
                .to(document.querySelector('.completed-content'), {
                    duration: 0.2,
                    opacity: 1,
                    scale: 1,
                    ease: 'power4.out',
                });
            return timeline;
        },
    },

    filters: {
        formatProduct(product) {
            switch (product) {
            case 'sms':
            case 'ussd':
                return product.toUpperCase();
            case 'iot':
                return 'IoT';
            case 'airtime':
            case 'voice':
                return product.charAt(0).toUpperCase() + product.slice(1);
            default:
                return product;
            }
        },

        formatRuntime(runtime) {
            switch (runtime) {
            case 'php':
                return runtime.toUpperCase();
            case 'pio':
                return 'PlatformIO';
            case 'java':
            case 'node':
            case 'python':
            case 'ruby':
                return runtime.charAt(0).toUpperCase() + runtime.slice(1);
            default:
                return runtime;
            }
        },
    },
};
