<template>
    <div v-if="this.productSupported && exploreTutorials.length > 0">
        <h2>Explore Tutorials</h2>
        <p>To get started ASAP, try out any of these {{ product | formatProduct }} tutorials in our interactive learning environment. You can also <router-link :to="`/tutorials?products=${product}`">explore all {{ product | formatProduct }} tutorials.</router-link></p>
        <div
            class="grid grid-cols-1 mt-8 gap-12 mb-32"
            :class="{
                'lg:grid-cols-1': hasCode,
                'lg:grid-cols-1': !hasCode
            }">
            <TutorialItem
                v-for="(tutorial, idx) in exploreTutorials"
                :key="tutorial.slug"
                :data-index="idx"
                :tutorial="tutorial"
                :size="'compact'"
            />
        </div>
    </div>
</template>

<script>
import {
    mapState,
    mapActions,
} from 'vuex';

import mixin from './mixin';

export default {
    name: 'TutorialExplore',

    mixins: [mixin],

    props: {
        product: {
            type: String,
            required: true,
        },

        hasCode: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            supportedProducts: [
                'sms',
                'ussd',
                'airtime',
                'iot',
            ],
            exploreTutorials: [],
        };
    },

    watch: {
        product(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.exploreTutorials = [];
                this.loadProductExploreTutorials();
            }
        },
    },

    computed: {
        ...mapState('tutorials', [
            'isLoadingTutorials',
            'tutorials',
        ]),

        productSupported() {
            return this.supportedProducts.includes(this.product);
        },
    },

    created() {
        this.loadProductExploreTutorials();
    },

    beforeDestroy() {
        this.exploreTutorials = [];
    },

    methods: {
        ...mapActions('tutorials', [
            'getTutorials',
        ]),

        async loadProductExploreTutorials() {
            try {
                if (this.productSupported) {
                    await this.getTutorials({
                        products: this.product,
                        count: 2,
                    });

                    this.exploreTutorials = this.tutorials;
                }
            } catch (ex) { /* Ignore */ }
        },
    },

    components: {
        TutorialItem: () => import('@/components/tutorials/TutorialItem.vue'),
    },
};
</script>
